import { graphql } from "gatsby";
import React from "react";

export const fragments = graphql`
    fragment MediaImageData on media__image {
        __typename
        name
        field_caption
        field_media_image {
            alt
            uri
        }
    }
    fragment MediaRemoteVideoData on media__remote_video {
        __typename
        name
        field_video_id
    }
    fragment MediaImageOrVideoData on MediaImageOrVideo {
        __typename
        ...MediaImageData
        ...MediaRemoteVideoData
    }

    fragment ParagraphAlignedImageAndTextData on paragraph__aligned_image_and_text {
        __typename
        id
        field_text {
            value
        }
        field_image_position
        relationships {
            field_featured_image {
                ...MediaImageData
            }
        }
    }
    fragment ParagraphButtonData on paragraph__link_button {
        __typename
        id
        field_link {
            title
            uri
        }
        field_button_theme
        field_button_icon
    }
    fragment ParagraphButtonData on paragraph__link_button {
        __typename
        id
        field_link {
            title
            uri
        }
        field_button_theme
        field_button_icon
    }
    fragment ParagraphCAQData on paragraph__commonly_asked_question_section {
        __typename
        id
        field_section_heading
        field_jump_links_title
        relationships {
            field_content {
                title
                field_jump_links_title
                field_answer {
                    value
                }
            }
        }
    }
    fragment ParagraphCaseStudyData on paragraph__case_study {
        __typename
        id
        field_heading
        field_sub_heading
        field_description
        field_link {
            title
            uri
        }
        relationships {
            field_featured_image {
                ...MediaImageData
            }
            field_case_study_story {
                title
                path {
                    alias
                }
                field_short_link_text
                relationships {
                    field_featured_image {
                        ...MediaImageData
                    }
                }
            }
        }
    }
    fragment ParagraphChecklistData on paragraph__checklist {
        __typename
        id
        field_heading
        field_jump_links_title
        field_text {
            value
        }
        field_list_type
        relationships {
            field_image {
                field_media_image {
                    alt
                    uri
                }
            }
        }
    }
    fragment ParagraphContactBlockData on paragraph__contact_component_block {
        __typename
        id
        field_heading
        field_contact_block_icon
        field_contact_block_text
        field_contact_block_url
    }
    fragment ParagraphContactUsData on paragraph__contact_us {
        __typename
        id
        relationships {
            field_contact_us_node {
                title
                field_jump_links_title
                field_contact_phone_number

                field_cta {
                    title
                    uri
                }
                body {
                    value
                }
                field_footer_text {
                    value
                }
            }
        }
    }
    fragment ParagraphContentReferenceArticlesData on paragraph__content_reference_articles {
        __typename
        id
        field_heading
        field_jump_links_title
        field_text {
            value
        }
        relationships {
            field_content {
                ...ContentUnionData
            }
        }
    }

    fragment ContentUnionData on ContentUnion {
        __typename
        ...ArticleContentData
        ...AppealContentData
        ...PageContentData
        ...BreedContentData
        ...EventContentData
        ...CentreContentData
        ...CentreArticleContentData
        ...CharityShopContentData
        ...LandingPageContentData
        ...TopicContentData
        ...OurStoryContentData
        ...StaticInternalPageReferenceContentData
    }

    fragment ArticleContentData on node__article {
        __typename
        title
        path {
            alias
        }
        field_node_weight
        field_short_description
        field_short_link_text
        drupal_id

        relationships {
            field_category_type {
                name
            }
            field_icon {
                field_media_image {
                    uri
                    alt
                }
            }
            field_featured_image {
                field_media_image {
                    uri
                    alt
                    title
                    width
                    height
                }
            }
        }
    }
    fragment AppealContentData on node__appeal {
        __typename
        title
        path {
            alias
        }
        field_short_description
        field_short_link_text

        relationships {
            field_featured_image {
                field_media_image {
                    uri
                    alt
                    title
                    width
                    height
                }
            }
        }
    }
    fragment PageContentData on node__page {
        __typename
        path {
            alias
        }
        title
        field_subtitle
        field_intro_text {
            processed
            value
        }
        relationships {
            field_images {
                field_media_image {
                    uri
                    alt
                }
            }
        }
    }
    fragment BreedContentData on node__breed {
        __typename
        id
        title
        path {
            alias
        }
        field_intro_copy
        relationships {
            field_featured_image {
                field_media_image {
                    uri
                    alt
                }
            }
            field_breed {
                name
            }
            field_shedding {
                name
            }
            field_size {
                name
            }
            field_activity_level {
                name
            }
        }
    }
    fragment EventContentData on node__event {
        __typename
        title
        path {
            alias
        }
        field_event_date
        relationships {
            field_featured_image {
                field_media_image {
                    uri
                    alt
                }
            }
        }
    }
    fragment CentreContentData on node__centre {
        __typename
        title
        path {
            alias
        }
        field_centre_lead_paragraph {
            value
        }
        relationships {
            field_centre_images {
                field_media_image {
                    uri
                    alt
                }
            }
        }
    }
    fragment CentreArticleContentData on node__centre_article {
        __typename
        title
        path {
            alias
        }
        field_centre_article_intro_text
        field_short_link_text
        relationships {
            field_hero_image {
                field_media_image {
                    uri
                    alt
                }
            }
        }
    }
    fragment CharityShopContentData on node__charity_shop {
        __typename
        title
        path {
            alias
        }
        field_shop_intro_header_text
        relationships {
            field_shop_images {
                field_media_image {
                    uri
                    alt
                }
            }
        }
    }
    fragment LandingPageContentData on node__landing_page {
        __typename
        path {
            alias
        }
        title
        drupal_id
        field_short_link_text
        field_intro_text {
            processed
            value
        }
        relationships {
            field_images {
                field_media_image {
                    uri
                    alt
                }
            }
            field_icon {
                field_media_image {
                    uri
                    alt
                }
            }
        }
    }
    fragment TopicContentData on node__topic {
        __typename
        path {
            alias
        }
        title
        drupal_id
        field_short_description
        field_short_link_text
        relationships {
            field_featured_image {
                field_media_image {
                    alt
                    title
                    width
                    height
                    uri
                }
            }
            field_icon {
                field_media_image {
                    uri
                    alt
                }
            }
            field_parent {
                name
            }
        }
    }
    fragment OurStoryContentData on node__our_story {
        __typename
        title
        path {
            alias
        }
        field_node_weight
        field_short_description
        field_short_link_text
        field_date
        relationships {
            field_category_type {
                name
            }
            field_featured_image {
                field_media_image {
                    uri
                    alt
                    title
                    width
                    height
                }
            }
            field_related_our_story_topics {
                name
			}
        }
    }
    fragment StaticInternalPageReferenceContentData on node__static_internal_page_reference {
        __typename
        title
        field_summary
        field_link {
            title
            uri
        }
        field_node_weight
        relationships {
            field_image {
                field_media_image {
                    alt
                    uri
                }
            }
            field_icon {
                field_media_image {
                    uri
                    alt
                }
            }
        }
    }
    fragment ParagraphContentSectionUnionData on ParagraphContentSectionUnion {
        __typename
        ...ParagraphContentSectionData
        ...ParagraphCentreAlignedContentSectionData
    }

    fragment ParagraphContentSectionData on paragraph__content_section {
        __typename
        id
        field_pre_heading
        field_heading
        field_jump_links_title
        field_theme
        relationships {
            __typename
            field_image {
                field_media_image {
                    alt
                    uri
                }
            }
            field_text_button {
                ...ParagraphTextOrButtonData
            }
        }
    }
    fragment ParagraphCentreAlignedContentSectionData on paragraph__centre_aligned_content_section {
        __typename
        id
        field_pre_heading
        field_heading
        field_theme
        relationships {
            __typename
            field_image {
                field_media_image {
                    alt
                    uri
                }
            }
            field_text_button {
                ...ParagraphTextOrButtonData
            }
        }
    }
    fragment ParagraphDogSizesData on paragraph__dog_sizes {
        __typename
        id
        field_title
        relationships {
            field_breed_size_block {
                ...ParagraphBreedSizeBlockData
            }
        }
    }

    fragment ParagraphBreedSizeBlockData on paragraph__breed_size_block {
        __typename
        id
        field_breed_size_description
        relationships {
            field_breed_size {
                name
            }
        }
        breedsBySize {
			...BreedContentData
		}
    }
    fragment ParagraphExternalSignpostLinkData on paragraph__external_signpost_link {
        __typename
        id
        relationships {
            field_ex_signpost_content {
                title
                field_external_signpost_summary
                field_link {
                    title
                    uri
                }

                relationships {
                    field_image {
                        name
                        field_media_image {
                            alt
                            title
                            width
                            height
                            uri
                        }
                    }
                }
            }
        }
    }
    fragment ParagraphFAQsData on paragraph__faqs {
        __typename
        id
        field_heading
        field_jump_links_title
        relationships {
            field_faq {
                ...ParagraphFAQData
            }
        }
    }

    fragment ParagraphFAQData on paragraph__faq {
        __typename
        id
        field_question
        field_jump_links_title
        field_answer {
            value
        }
    }
    fragment ParagraphFileDownloadData on paragraph__file_download {
        __typename
        id
        downloadUrl
        fileSize
        field_download_label
    }
    fragment ParagraphGroupOfExpandableSectionsData on paragraph__group_of_expandable_sections {
        __typename
        id
        field_section_title
        field_jump_links_title
        field_text {
            value
        }
        field_group_theme
        relationships {
            field_expandable_section {
                ...ParagraphTextWithArticlesData
            }
            field_featured_image {
                ...MediaImageData
            }
        }
    }
    fragment ParagraphHighlightedQuotesData on paragraph__highlighted_quotes {
        __typename
        id
        relationships {
            field_quote_block {
                ...ParagraphQuoteData
            }
        }
    }
    fragment ParagraphHighlightedTextAreaData on paragraph__highlighted_text_area {
        __typename
        id
        field_heading
        field_text {
            value
        }
        relationships {
            field_icon {
                ...MediaImageData
            }
        }
    }
    fragment ParagraphImageOrTextData on ParagraphImageOrText {
        __typename
        ...ParagraphStepTextData
        ...ParagraphStepImageData
    }

    fragment ParagraphStepTextData on paragraph__step_text {
        __typename
        id
        field_text {
            value
        }
    }
    fragment ParagraphStepImageData on paragraph__step_image {
        __typename
        id
        relationships {
            field_featured_image {
                ...MediaImageData
            }
        }
    }
    fragment ParagraphImagesData on paragraph__image {
        __typename
        id
        relationships {
            field_image {
                name
                field_media_image {
                    alt
                    title
                    width
                    height
                    uri
                }
            }
        }
    }
    fragment ParagraphJumpLinksData on paragraph__jump_links {
        __typename
        id
        field_title
        field_jump_links_text
		field_show_heading_types
    }
    fragment ParagraphKeyFactsFiguresData on paragraph__key_facts_figures {
        __typename
        id
        field_heading
        field_jump_links_title
        field_description
        relationships {
            field_icon {
                ...MediaImageData
            }
        }
    }
    fragment ParagraphMediaContentListData on paragraph__media_content_list {
        __typename
        id
        relationships {
            field_media_content_item {
                ...ParagraphMediaContentItemData
            }
        }
    }

    fragment ParagraphMediaContentItemData on paragraph__media_content {
        __typename
        id
        field_heading
        field_text {
            value
        }
        relationships {
            field_featured_image {
                ...MediaImageData
            }
        }
    }
    fragment ParagraphMissionStatementData on paragraph__mission_statement {
        __typename
        id
        field_heading
        field_jump_links_title
        field_sub_heading
        field_ms_description {
			value
		}
        field_image_mask_option
        field_background_colour_option
        field_link {
            title
            uri
        }
        relationships {
            field_featured_image {
                ...MediaImageData
            }
        }
    }
    fragment ParagraphNationalDogSurveyCtaData on paragraph__national_dog_survey_cta {
        __typename
        id
        field_cta_title
        field_nds_description {
            value
        }
    }
    fragment ParagraphPopularTopicsData on paragraph__popular_topics {
        __typename
        id
        field_section_title
        relationships {
            field_popular_topic {
                field_parent_link {
                    title
                    uri
                }
                field_child_links {
                    title
                    uri
                }
            }
        }
    }
    fragment ParagraphQuoteData on paragraph__quote {
        __typename
        id
        field_quote
        field_quotee
        field_quotee_role
		field_is_highlighted
    }
    fragment ParagraphSectionData on paragraph__section {
        __typename
        id
        relationships {
            field_section {
                ...SectionData
            }
        }
    }
    fragment ParagraphShareableCarouselData on paragraph__shareable_carousel {
		__typename
		id
		shareableImages {
			imagePageUrl
			imageSrc
			imageAlt
		}
	}
    fragment ParagraphSponsorMePromoCardData on paragraph__sponsor_me_promo_card {
        __typename
        id
        relationships {
            field_sponsor_dog {
                ...SponsorMePromoCardData
            }
        }
    }
    fragment SponsorMePromoCardData on node__sponsor_dog {
        field_promo_short_text
        id
        path {
            alias
        }
        relationships {
            field_featured_image {
                ...MediaImageData
            }
        }
    }
    fragment ParagraphSteppedProcessData on paragraph__stepped_process {
        __typename
        id
        relationships {
            field_stepped_process_node {
                title
                field_jump_links_title
                field_stepped_intro_text
                relationships {
                    field_component_type {
                        name
                    }
                    field_step {
                        field_step_title
                        field_step_intro_text {
                            value
                        }
                        field_step_link {
                            title
                            uri
                        }
                        relationships {
                            field_image_text {
                                ...ParagraphImageOrTextData
                            }
                        }
                    }
                }
            }
        }
    }
    fragment ParagraphSuccessStoriesData on paragraph__success_stories {
        __typename
        id
        relationships {
            field_success_story {
                ...ParagraphSuccessStoryData
            }
        }
    }

    fragment ParagraphSuccessStoryData on paragraph__success_story {
        __typename
        id
        field_heading
        field_dog_name
        field_date
        field_text {
            value
        }
        relationships {
            field_featured_image {
                ...MediaImageData
            }
        }
    }
    fragment ParagraphSupportCardsData on paragraph__support_cards {
        __typename
        id
        field_heading
        field_jump_links_title
        field_text {
            value
        }
        relationships {
            field_content {
                ...ContentUnionData
            }
        }
    }
    fragment ParagraphSupportWidgetData on paragraph__support_widget {
        __typename
        id
        field_title
        field_jump_links_title
        field_widget_text
        field_embed_code
        field_widget_theme
    }
    fragment ParagraphSupportWidgetPrimaryData on paragraph__support_widget_primary {
        __typename
        id
        field_title
        field_jump_links_title
        field_widget_text_html {
            value
        }
        field_embed_code
        field_widget_theme
        field_section_theme
    }
    fragment ParagraphTextData on paragraph__text {
        __typename
        id
        field_text {
            value
        }
    }
    fragment ParagraphTextAndImageData on paragraph__text_and_image {
        __typename
        id
        field_heading
        field_jump_links_title
        field_text {
            value
        }
        field_link {
            title
            uri
        }
        relationships {
            field_featured_image {
                ...MediaImageData
            }
        }
    }
    fragment ParagraphTextAreaData on paragraph__textarea {
        __typename
        id
        field_text {
            value
        }
		field_hide_from_jump_links
    }
    fragment ParagraphTextOrButtonData on ParagraphTextOrButton {
        __typename
        ...ParagraphTextData
        ...ParagraphButtonData
    }
    fragment ParagraphTextWithArticlesData on paragraph__text_with_articles {
        __typename
        id
        field_section_title
        field_jump_links_title
        field_intro_copy {
            value
        }
        field_expandable_enable_bc
        relationships {
			field_image {
                field_media_image {
                    alt
                    uri
                }
            }
            field_article_picker {
                title
                path {
                    alias
                }
                field_node_weight
                field_short_description
                field_short_link_text

                relationships {
                    field_category_type {
                        name
                    }
                    field_featured_image {
                        field_media_image {
                            uri
                            alt
                            title
                            width
                            height
                        }
                    }
                }
            }
        }
    }
    fragment ParagraphVideoData on paragraph__video {
        __typename
        id
        field_heading
        field_jump_links_title
        field_text {
            value
        }
		poster
        relationships {
            field_video {
                ...MediaVideoData
            }
        }
    }

    fragment MediaVideoData on media__remote_video {
        id
        name
        field_video_id
    }
    fragment ParagraphImagesData on paragraph__image {
        __typename
        id
        relationships {
            field_image {
                name
                field_media_image {
                    alt
                    title
                    width
                    height
                    uri
                }
            }
        }
    }
 fragment PromotionData on node__promotion {
        id
        field_title_front_end
        field_text
        field_promo_link {
            uri
            title
        }
        field_show_on_dog_listings
        relationships {
            field_promo_image {
                ...MediaImageData
            }
            field_promotion_type {
                name
            }
        }
    }

    fragment SectionData on node__section {
        __typename
        title
        field_front_end_title
        field_jump_links_title
        field_filter_by_dog_age
        field_min_items_to_display
        field_max_items_to_display
        field_json_config
        field_section_footer_text {
            processed
            value
        }
        field_link {
            title
            uri
        }
        field_section_short_description {
            processed
            value
        }
        relationships {
            field_method_of_ordering_items {
                name
            }
            field_component_type {
                name
            }
            field_extra_section_info {
                field_extra_information {
                    value
                }
            }
            field_large_featured_promo_image {
                name
            }
            field_related_topic {
                name
            }
            field_icon {
                field_media_image {
                    uri
                    alt
                }
            }
            field_related_map_type_content {
                name
            }
            field_filter_by_dog_size {
                name
            }
        }
        promotedLinks {
            ...PromoteToUnionData
        }
        cmsMapCards {
            ...CmsMapCard
        }
    }
    fragment SignpostUnionData on SignpostUnion {
        __typename
        ...ArticleSignPostData
        ...AppealSignPostData
        ...ExternalSignPostData
        ...StaticInternalPageReferenceData
        ...LandingPageSignPostData
        ...OurStorySignPostData
    }

    fragment ArticleSignPostData on node__article {
        __typename
        title
        field_short_description
        field_short_link_text
        path {
            alias
        }
        field_node_weight
        relationships {
            field_icon {
                field_media_image {
                    uri
                    alt
                }
            }
            field_featured_image {
                field_media_image {
                    uri
                    alt
                }
            }
            field_category_type {
                name
            }
            field_related_topics_categories {
                name
            }
        }
    }

    fragment AppealSignPostData on node__appeal {
        __typename
        title
        path {
            alias
        }
        field_short_description
        field_short_link_text
        relationships {
            field_featured_image {
                field_media_image {
                    uri
                    alt
                }
            }
            field_category_type {
                name
            }
        }
    }

    fragment OurStorySignPostData on node__our_story {
        __typename
        title
        field_short_description
        field_short_link_text
        path {
            alias
        }
        field_node_weight
        relationships {
            field_icon {
                field_media_image {
                    uri
                    alt
                }
            }
            field_featured_image {
                field_media_image {
                    uri
                    alt
                }
            }
            field_category_type {
                name
            }
        }
    }

    fragment ExternalSignPostData on node__external_signposts {
        __typename
        title
        field_external_signpost_summary
        field_link {
            title
            uri
        }
        relationships {
            field_image {
                field_media_image {
                    alt
                    uri
                }
            }
        }
    }

    fragment StaticInternalPageReferenceData on node__static_internal_page_reference {
        __typename
        title
        field_summary
        field_link {
            title
            uri
        }
        field_node_weight
        relationships {
            field_image {
                field_media_image {
                    alt
                    uri
                }
            }
            field_icon {
                field_media_image {
                    alt
                    uri
                }
            }
        }
    }

    fragment LandingPageSignPostData on node__landing_page {
        __typename
        title
        field_intro_text {
            processed
            value
        }
        field_short_link_text
        field_priority_weight
        path {
            alias
        }
        relationships {
            field_icon {
                field_media_image {
                    uri
                    alt
                }
            }
            field_images {
                field_media_image {
                    uri
                    alt
                }
            }
            field_category_type {
                name
            }
        }
    }

    fragment PromotedArticle on node__article {
        __typename
        title
        path {
            alias
        }
        breadcrumbs {
            ...BreadCrumb
        }
        field_body {
            value
        }
        created
        field_author
        field_is_vet_friendly_clinic
        field_short_description
        field_short_link_text
        field_show_newsletter_promo
        field_meta_tags {
            ...MetaTags
        }
        relatedTopicLinks {
            title
            uri
        }
        relationships {
            field_icon {
                field_media_image {
                    uri
                    alt
                }
            }
            field_featured_image {
                field_media_image {
                    uri
                    alt
                }
            }
            field_related_help_advice_articl {
                ...ContentUnionData
            }
            field_category_type {
                name
            }
        }
    }

    fragment PromotedAppeal on node__appeal {
        __typename
        title
        path {
            alias
        }
        breadcrumbs {
            ...BreadCrumb
        }
        field_short_description
        field_short_link_text
        field_meta_tags {
            ...MetaTags
        }
        relationships {
            field_featured_image {
                field_media_image {
                    uri
                    alt
                }
            }
        }
    }

    fragment PromotedLandingPage on node__landing_page {
        __typename
        title
        path {
            alias
        }
        breadcrumbs {
            title
            url
        }

        field_pre_heading
        field_intro_text {
            processed
            value
        }
        field_is_sub_landing_page
        field_short_link_text
        field_meta_tags {
            title
            description
            abstract
            keywords
            canonical_url
            robots
            og_type
            og_url
            og_title
            og_description
            og_image_url
            twitter_cards_type
            twitter_cards_description
            twitter_cards_site
            twitter_cards_title
            twitter_cards_page_url
            twitter_cards_image
        }
        relationships {
            field_intro_type {
                name
            }
            field_icon {
                field_media_image {
                    uri
                    alt
                }
            }
            field_images {
                field_media_image {
                    uri
                    alt
                }
            }
            field_category_type {
                name
            }
        }
    }
    fragment PromotedOurStory on node__our_story {
        __typename
        title
        path {
            alias
        }
        breadcrumbs {
            ...BreadCrumb
        }
        field_body {
            value
        }
        field_author
        field_short_description
        field_short_link_text
        field_date
        field_show_newsletter_promo
        field_meta_tags {
            ...MetaTags
        }
        relatedStoryTopicTags {
            title
        }
        relationships {
            field_icon {
                field_media_image {
                    uri
                    alt
                }
            }
            field_featured_image {
                field_media_image {
                    uri
                    alt
                }
            }
            field_category_type {
                name
            }
        }
    }
    fragment PromotedBreed on node__breed {
        __typename
        title
        path {
            alias
        }
        breadcrumbs {
            ...BreadCrumb
        }
        field_intro_copy
        relationships {
            field_featured_image {
                field_media_image {
                    uri
                    alt
                }
            }
        }
    }

    fragment CmsMapCard on node__map_card {
        __typename
        status
        id
        title
        field_date
        field_google_maps_link
        field_latitude
        field_link {
            title
            uri
        }
        field_map_card_location
        field_longitude
        field_secondary_info_text
        field_secondary_info_title
        field_subtitle
        field_tertiary_info_text {
            processed
            value
        }
        relationships {
            field_content_type {
                name
                pinIconUrl
                pinIconSelectedUrl
                relationships {
                    field_map_pin_icon {
                        relationships {
                            field_media_file {
                                uri {
                                    value
                                }
                            }
                        }
                    }
                    field_map_pin_icon_selected {
                        relationships {
                            field_media_file {
                                uri {
                                    value
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    fragment VolunteerRoleData on node__volunteer_role {
        title
        path {
            alias
        }
        relationships {
            field_featured_image {
                field_media_image {
                    uri
                    alt
                }
            }
        }
    }
    fragment SponsorDogData on node__sponsor_dog {
		apiKey
		profileUrl
        title
        path {
            alias
        }
        breadcrumbs {
            ...BreadCrumb
        }
        field_about_me {
            value
        }
        field_can_be_visited
        field_date
        fundCode
        field_quote
        field_quotee
        field_quotee_role
        field_sex
        field_tag_line
        field_promo_short_text
		imagePath
        field_meta_tags {
            ...MetaTags
        }
        rehomingCentre {
            title
            field_latitude
            field_longitude
        }
        field_about_me {
            value
        }
        field_why_i_need_sponsoring {
            value
        }
        relationships {
            field_breed {
                name
            }
            field_centre_code {
                name
            }
            field_likes {
                name
            }
            field_dislikes {
                name
            }
            field_featured_image {
                ...MediaImageData
            }
            field_gallery_of_images_or_video {
                ...MediaImageOrVideoData
            }
            field_page_sections {
                ...ParagraphSectionsData
            }
        }
    }
    fragment PromoteToUnionData on PromoteToUnion {
        __typename
        ...PromotedArticle
        ...PromotedAppeal
        ...ExternalSignPostData
        ...StaticInternalPageReferenceData
        ...PromotedLandingPage
        ...PromotedOurStory
        ...PromotedBreed
        ...Dog
    }
    fragment ParagraphSectionsData on ParagraphSections {
        __typename
        ...ParagraphSectionData
        ...ParagraphContentSectionUnionData
        ...ParagraphCAQData
        ...ParagraphContactUsData
        ...ParagraphImagesData
        ...ParagraphTextAreaData
        ...ParagraphVideoData
        ...ParagraphChecklistData
        ...ParagraphQuoteData
        ...ParagraphSteppedProcessData
        ...ParagraphContentReferenceArticlesData
        ...ParagraphButtonData
        ...ParagraphPopularTopicsData
        ...ParagraphExternalSignpostLinkData
        ...ParagraphSupportCardsData
        ...ParagraphTextWithArticlesData
        ...ParagraphSuccessStoriesData
        ...ParagraphFAQsData
        ...ParagraphFileDownloadData
        ...ParagraphHighlightedTextAreaData
        ...ParagraphSponsorMePromoCardData
        ...ParagraphTextAndImageData
        ...ParagraphSupportWidgetData
        ...ParagraphSupportWidgetPrimaryData
        ...ParagraphContactBlockData
        ...ParagraphHighlightedQuotesData
        ...ParagraphMediaContentListData
        ...ParagraphAlignedImageAndTextData
		...ParagraphGroupOfExpandableSectionsData
		...ParagraphKeyFactsFiguresData
		...ParagraphMissionStatementData
		...ParagraphCaseStudyData
		...ParagraphNationalDogSurveyCtaData
		...ParagraphDogSizesData
		...ParagraphJumpLinksData
		...ParagraphShareableCarouselData
    }



    query StaticData {
			allTaxonomyTermDogShedding {
				nodes {
					id
					name
				}
			}
			allTaxonomyTermDogSize {
				nodes {
					id
					name
				}
			}
			allTaxonomyTermBreed {
				nodes {
					id
					name
				}
			}
            allTaxonomyTermDogActivityLevel {
                nodes {
                    id
                    name
                }
            }


			allNodeBreed {
				nodes {
					...BreedContentData
				}
			}
			allNodeOurStory {
				nodes {
					...OurStoryContentData
				}
			}

			navPromos: allNodePromotion(filter: {
                relationships: {
                    field_promotion_type: { name: { eq: "PromoCardNav" } }
                }
            }) {
				nodes {
					...PromotionData
				}
			}

			dogListingPromos: allNodePromotion(filter: { field_show_on_dog_listings: {eq: true} } ) {
				nodes {
				...PromotionData
				}
			}
            
			allNodeSponsorDog {
				nodes {
					...SponsorDogData
				}
			}
			allNodeVolunteerRole {
				nodes {
					...VolunteerRoleData
				}
			}
		}
`;

export const Fragments: React.FC = () => <></>;
